@import "minima";
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400&display=swap');

.wrapper {
  max-width: 600px
}
header {
  font-family: 'IBM Plex Sans', sans-serif;
}
h1 h2 h3 h4 h5 {
  font-family: 'IBM Plex Sans', sans-serif;
}

body {
  font-family: 'Source Serif Pro', serif;
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin-bottom: 1.5rem;
}

blockquote {
  margin-bottom: 2rem;
  letter-spacing: 0px;
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

span.hide-spoiler::selection,
span.hide-spoiler {
  color: black;
  background-color: black;
}

div.post-content {
  ul {
      list-style: none;
  }
  ul > li:before {
      display: inline-block;
      content: "-";
      width: 1em;
      margin-left: -1em;
  }
}
